import axios from "@/config/http.js";

export const API = {
  // 广告信息查询
  async pageIndex(params) {
    return await axios.get("/manage/advertise/list", { params });
  },
  // 商品分类下拉
  async goodsCategory() {
    return await axios.get("/manage/advertise/dropdown/goodsCategory");
  },
  // 清空广告
  async deleteAdvertise(id) {
    return await axios.delete(`/manage/advertise?advertiseId=${id}`);
  },
  // 商品跳转地址
  async goodsJumpAddress(params) {
    return await axios.get("/manage/advertise/page/goods", { params });
  },
  // 广告信息保存
  async addAdvertise(params) {
    return await axios.put("/manage/advertise/save", params);
  },
};
