var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jump-address-container"},[_c('a-modal',{staticClass:"modal-style",attrs:{"visible":_vm.visibleModal,"maskClosable":false,"width":1490},on:{"cancel":_vm.cancel}},[_c('div',[_vm._v("跳转地址")]),_c('a-divider'),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("返回")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1),_c('a-row',{staticClass:"mb2"},[_c('a-form-model',{staticStyle:{"display":"flex"},attrs:{"layout":"inline"}},[_c('a-form-model-item',{attrs:{"label":"商品名称"}},[_c('a-input',{attrs:{"placeholder":"请输入"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryBtn.apply(null, arguments)}},model:{value:(_vm.queryFrom.goodsName),callback:function ($$v) {_vm.$set(_vm.queryFrom, "goodsName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryFrom.goodsName"}})],1),_c('a-form-model-item',{attrs:{"label":"商品分类"}},[_c('a-cascader',{staticStyle:{"width":"28rem"},attrs:{"placeholder":"请选择","expand-trigger":"hover","display-render":_vm.displayRender,"options":_vm.treeData,"field-names":{
              label: 'label',
              value: 'id',
              children: 'children',
            }},model:{value:(_vm.queryFrom.goodsCategoryId),callback:function ($$v) {_vm.$set(_vm.queryFrom, "goodsCategoryId", $$v)},expression:"queryFrom.goodsCategoryId"}})],1),_c('a-form-model-item',{attrs:{"label":"工厂名称"}},[_c('a-input',{attrs:{"placeholder":"请输入"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryBtn.apply(null, arguments)}},model:{value:(_vm.queryFrom.factoryName),callback:function ($$v) {_vm.$set(_vm.queryFrom, "factoryName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryFrom.factoryName"}})],1),_c('div',{staticClass:"right",staticStyle:{"line-height":"4rem"}},[_c('a-button',{attrs:{"type":"reload","icon":"redo"},on:{"click":_vm.handleReset}},[_vm._v("重置")]),_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":_vm.queryBtn}},[_vm._v("查询")])],1)],1)],1),_c('a-table',{key:_vm.data.key,attrs:{"columns":_vm.columns,"data-source":_vm.data,"loading":_vm.loading,"scroll":{ y: 600 },"pagination":_vm.pagination,"rowClassName":_vm.setBackground,"customRow":function (record, index) {
          return {
            on: {
              click: function (event) { return _vm.onRowClick(event, record, index); },
            },
          };
        }},scopedSlots:_vm._u([{key:"goodsName",fn:function(text, record, index){return [_c('a-radio',{attrs:{"checked":_vm.preIndex === index},on:{"change":function($event){return _vm.handleRadioChange(index)}}}),_c('span',{attrs:{"title":text}},[_vm._v(_vm._s(text))])]}},{key:"factoryName",fn:function(text, record){return [_c('span',{style:({ color: ("" + (_vm.getColor('主题'))) }),attrs:{"title":record.factoryName}},[_vm._v(_vm._s(text))])]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }